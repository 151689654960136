import React from 'react';
import styles from './workshops-intro.module.scss';
import classnames from 'classnames';

export const WorkshopsIntro = () => (
  <section className={styles.workshopsIntro}>
    <div className={styles.prefix}>
      <div className={styles.prefixImg}>
        <img src="/images/babyface3.jpg" alt="baby" />
      </div>
      <strong>
        <b>Parents with children are invited to the workshops!</b>at the
        workshops you will learn
      </strong>
      <div className={styles.prefixImg}>
        <img src="/images/babyface4.jpg" alt="baby" />
      </div>
    </div>
    <div className="columns is-gapless">
      <div className="column">
        <div className={classnames([styles.card, styles.cardOne])}>
          <span className={styles.light}>
            feeding, breathing and speech development
          </span>
          <em></em>
          <div className={styles.bottom}>
            <ul>
              <li>how to feed properly (breast or bottle)</li>
              <li>
                how does proper feeding and breathing affect the child's
                psychomotor development?
              </li>
              <li>
                how the proper development of primary functions (breathing and
                eating) has an impact on the development of a child's speech
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="column">
        <div className={classnames([styles.card, styles.cardTwo])}>
          <span>psycho-movement development in the 1st year</span>
          <em></em>
          <div className={styles.bottom}>
            <ul>
              <li>
                how important is child's harmonious development and how does
                proper development work in the first year of life
              </li>
              <li>
                when and what competences of fine and small motor skills acquire
                and how it affects its subsequent development
              </li>
              <li>how to support a child in development</li>
              <li>how to stimulate the development of a child's speech</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="column">
        <div className={classnames([styles.card, styles.cardThree])}>
          <span className={styles.light}>bonding development</span>
          <em></em>
          <div className={styles.bottom}>
            <ul>
              <li>how to bond with your child from the first days</li>
              <li>how to massage a child - learning the Shantala massage</li>
              <li>
                how important touch is in the development of the child's nervous
                system
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="column">
        <div className={classnames([styles.card, styles.cardFour])}>
          <span>newborn hygene</span>
          <em></em>
          <div className={styles.bottom}>
            <ul>
              <li>how to support breastfeeding</li>
              <li>how to properly hold a child</li>
              <li>how to cultivate a baby's belly button</li>
              <li>how to bathe and change nappies</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div className={styles.suffix}>
      <div className={styles.suffixImg}>
        <img src="/images/baby-yawn.jpg" alt="baby" />
      </div>
      <strong>
        and many other issues that will allow you to take care of your child in
        a conscious way
        <p>
          Workshops' dates are subject to level of interest and group sizes!
        </p>
      </strong>
      <div className={styles.suffixImg}>
        <img src="/images/babyface2.jpg" alt="baby" />
      </div>
    </div>
  </section>
);
