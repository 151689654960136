import React from 'react';
import classnames from 'classnames';
import styles from './hero.module.scss';

export const Hero = () => (
  <div className={styles.hero}>
    <span className={classnames([styles.circle, styles.circleLeft])}></span>
    <div className={styles.heroInner}>
      <div className="columns is-vcentered">
        <div className="column is-two-thirds">
          <ul className={styles.slogan}>
            <li>
              Have you become <span>a parent</span> for the first time?
            </li>
            <li>
              Or maybe you are once again enjoying <span>motherhood</span>?
            </li>
            <li>
              Whether it's your first or second child, these{' '}
              <span>workshops</span> are especially for you.
            </li>
          </ul>
        </div>
        <div className="column">
          <div className={styles.baby}>
            <img src="/images/babyface1-400.jpg" alt="Oopsy Daisy Baby" />
          </div>
        </div>
      </div>
    </div>
    <span className={classnames([styles.circle, styles.circleRight])}></span>
  </div>
);
