import React from 'react';
import { Hero } from '../components/hero';
import { WorkshopsIntro } from '../components/workshops-intro';
import Layout from '../components/layout';

export default () => {
  return (
    <Layout>
      <Hero />
      <WorkshopsIntro />
    </Layout>
  );
};
